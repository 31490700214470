import type { ImageLoader } from 'next/image'
import Image from 'next/image'
import NextLink from 'next/link'

import type { ComponentAnalyticsClickProps } from '@cms/analytics/types'
import useDevicePixelRatio from '@cms/hooks/useDevicePixelRatio'
import useNormalizeReference from '@cms/hooks/useNormalizeReference'
import { FALLBACK_IMAGES, SMART_CROP_TOKENS } from '@cms/utils/constants'
import type { FormattedVideoProps } from '@cms/utils/types'
import {
  getDefaultImageLoader,
  getDevicePixelRatioPriority,
  getImageWithFallback,
  getRenditionImageLoader,
  getSmartCropImageLoaderByTokenName,
} from '@cms/utils/utils'
import { formattedVideo } from '@cms/utils/video'
import type { TeaserProps } from '@components/Teaser'
import { Teaser } from '@components/Teaser'
import { useOneTrust } from '@knauf-group/ct-shared-nextjs/web/hooks/useOneTrust'

import { ChangeCookieSettingsCTA } from '../ChangeCookieSettingsCTA'
import type { TeaserWrapperProps } from './TeaserWrapper.types'

export const getImageLoader = (
  isProductImage: boolean,
  isSmartCropImage: boolean,
  devicePixelRatio: number,
): ImageLoader => {
  const devicePixelRatioPriority = getDevicePixelRatioPriority(devicePixelRatio)

  if (isProductImage) {
    return getRenditionImageLoader(devicePixelRatioPriority)
  }
  if (isSmartCropImage) {
    return getSmartCropImageLoaderByTokenName(
      SMART_CROP_TOKENS.CF_4X3_L,
      devicePixelRatioPriority,
    )
  }
  return getDefaultImageLoader()
}

const TeaserWrapper: React.FC<TeaserWrapperProps> = ({ fields, dataCy }) => {
  const oneTrust = useOneTrust()
  const { normalizeReference } = useNormalizeReference()
  const analyticsProps: ComponentAnalyticsClickProps = {
    targetType: 'link',
    cmsComponent: 'content_teaser',
  }

  const { image, primaryReference: primaryLink, videoReference } = fields || {}
  const primaryRef = normalizeReference({ reference: primaryLink, analyticsProps })

  const { devicePixelRatio } = useDevicePixelRatio()

  const isProductImage = Boolean(fields?.productImage)
  const isSmartCropImage = !isProductImage && image?.[0]?.url
  // empty loader triggers the default one which applies the Optimization API which serves them directly from the app. See https://nextjs.org/docs/pages/building-your-application/optimizing/images#loaders
  const imageLoader = getImageLoader(isProductImage, isSmartCropImage, devicePixelRatio)
  const cookieBanner = <ChangeCookieSettingsCTA />
  const formatedVideoProps: FormattedVideoProps = {
    videoContent: videoReference,
    index: 1,
    isTargetingCookieSet: oneTrust.isTargetingCookieSet,
    cookieSettingsTile: cookieBanner,
  }
  const video = formattedVideo(formatedVideoProps)
  // since we are returning fallback images on getImageWithFallback, we need to ensure it does not overlap with video thumbnail
  const imageWithFallback =
    !video && getImageWithFallback(image?.[0], FALLBACK_IMAGES.DEFAULT_4_3_RATIO)

  const teaserProps: TeaserProps = {
    ...fields,
    image: imageWithFallback,
    video,
    primaryRef,
    isVideoCookieAccepted: video?.isVideoCookieAccepted,
    cookieSettingsTile: cookieBanner,
    nextLinkComponent: NextLink,
    nextImageComponent: Image,
    imageLoader,
    isProductImage,
    dataCy,
  }

  return <Teaser {...teaserProps} />
}

export default TeaserWrapper

import type { Styles } from '@knauf-group/ct-designs/utils/types'
import type { Theme } from '@mui/material/styles'

const getGridContainerFlexDirection = (isRtl: boolean, imageAlignment: string) => {
  if (imageAlignment === 'Left') {
    return isRtl ? 'row-reverse' : 'row'
  }

  return isRtl ? 'row' : 'row-reverse'
}

const getGridContainerJustifyContent = (isRtl: boolean, imageAlignment: string) => {
  if (imageAlignment === 'Left') {
    return isRtl ? 'space-between' : 'start'
  }

  return isRtl ? 'start' : 'space-between'
}

export const useStyles = (
  theme: Theme,
  imageAlignment: string,
  isProductImage: boolean,
): Styles => {
  const isRTL = theme.direction === 'rtl'
  return {
    container: {
      flexGrow: 1,
      flexDirection: getGridContainerFlexDirection(isRTL, imageAlignment),
      justifyContent: getGridContainerJustifyContent(isRTL, imageAlignment),
    },
    videoThumbnail: {
      width: '100%',
      height: '100%',
      aspectRatio: '16/9',
      objectFit: 'cover',
    },
    image: {
      width: '100%',
      height: 'auto',
      aspectRatio: '4/3',
      objectFit: isProductImage ? 'contain' : 'cover',
      borderRadius: '2px',
    },
  }
}

import { useTranslation } from 'next-i18next'

import { WebLink } from '@knauf-group/ct-designs/components/core/WebLink'
import { blue } from '@knauf-group/ct-designs/themeConfigs/rebranding/colors'
import type { QaHook, Styles } from '@knauf-group/ct-designs/utils/types'
import { setSx } from '@knauf-group/ct-designs/utils/utils'
import CookieOutlinedIcon from '@mui/icons-material/CookieOutlined'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import { showCookieInfoPopup } from '../OneTrustCookieManager'

export type ChangeCookieSettingsCTAProps = {
  showCookieAlert?: boolean
} & QaHook

export const ChangeCookieSettingsCTA: React.FC<ChangeCookieSettingsCTAProps> = ({
  dataCy = 'change-cookie-settings-cta',
  showCookieAlert = true,
}) => {
  const { t } = useTranslation('cms', { keyPrefix: 'common.cookies' })
  const contentDisabledText = t('cookiesSettingsMediaDisabledText')
  const contentDisabledTextSplit = contentDisabledText.split(/[{}]/)
  const iconSize = { md: '64px', sm: '56px', xs: '40px' }
  const styles: Styles = {
    container: {
      display: 'flex',
      flexDirection: { xs: 'row', sm: 'column' },
      background: blue[100],
      padding: { xs: '8px', sm: '8px 16px' },
      gap: '4px',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      aspectRatio: '16/9',
      '& .MuiSvgIcon-root': {
        height: iconSize,
        width: iconSize,
        color: blue[200],
      },
    },
  }

  return (
    <Box sx={[...setSx(styles.container)]} data-cy={dataCy}>
      <CookieOutlinedIcon />
      {showCookieAlert && (
        <Typography variant="caption" fontWeight="600">
          {contentDisabledTextSplit[0]}
          <WebLink reference={null} sx={{ display: 'initial' }} onClick={showCookieInfoPopup}>
            {t('cookiesSettingsLink')}
          </WebLink>
          {contentDisabledTextSplit?.[2]}
        </Typography>
      )}
    </Box>
  )
}

export default ChangeCookieSettingsCTA

import type { ImageWeb, VideoProps } from '@knauf-group/ct-designs/utils/types'
import type {
  TypeContentDamVideoSkeleton,
  TypeContentVimeoVideoSkeleton,
  TypeContentYoutubeVideoSkeleton,
} from '@knauf-group/ct-shared-nextjs/web/contentful/generated-types'
import { CONTENTFUL_TYPES } from '@knauf-group/ct-shared-nextjs/web/utils/constants'
import type { ContentEntry } from '@knauf-group/ct-shared-nextjs/web/utils/types'

import {
  FALLBACK_IMAGES,
  VIMEO_HOST_URL,
  VIMEO_URL_REGEX,
  YOUTUBE_NOCOOKIE_HOST_URL,
  YOUTUBE_URL_REGEX,
} from './constants'
import type { FormattedVideoProps } from './types'

export const getYoutubeId = (id: string) => {
  return id?.match(YOUTUBE_URL_REGEX)?.[3]
}

export const getYoutubeNoInitialCookieUrl = (id: string) => {
  return `${YOUTUBE_NOCOOKIE_HOST_URL}/embed/${id}`
}

// Vimeo id digits are not consistent thus just checking if vimeo url and append dnt
const getVimeoNoInitialCookieUrl = (url: string) => {
  const vimeoUrlMatch = url?.match(VIMEO_URL_REGEX)?.[0]
  if (vimeoUrlMatch) {
    return `${url}?dnt=1`
  }

  return null
}

// TODO Once videoReference content type is unified, this should be replaced by getNoInitialCookieUrlByContentType
export const getNoInitialCookieUrl = (url: string) => {
  const youtubeVideoId = getYoutubeId(url)
  if (youtubeVideoId != null) {
    return getYoutubeNoInitialCookieUrl(youtubeVideoId)
  }

  return getVimeoNoInitialCookieUrl(url) || url
}

/**
 * Handles the generation of no initial cookie url for both videoReference as id or url
 * @param contentType - if id is provided, this param is required to identify the video type
 * @param videoReference - could be and id or url
 * @returns and url with no initial cookie
 */
export const getNoInitialCookieUrlByContentType = (
  contentType: string,
  videoReference: string,
) => {
  if (contentType === CONTENTFUL_TYPES.CONTENT_YOUTUBE_VIDEO) {
    const youtubeVideoId = getYoutubeId(videoReference) || videoReference
    return getYoutubeNoInitialCookieUrl(youtubeVideoId)
  }

  if (contentType === CONTENTFUL_TYPES.CONTENT_VIMEO_VIDEO) {
    return (
      getVimeoNoInitialCookieUrl(videoReference) || `${VIMEO_HOST_URL}/${videoReference}?dnt=1`
    )
  }

  return ''
}

const FALLBACK_THUMBNAIL: ImageWeb = {
  title: 'Fallback Thumbnail',
  url: FALLBACK_IMAGES.VIDEO_THUMBNAIL,
  priority: true,
}

const YOUTUBE_THUMBNAIL_HOST = 'https://i.ytimg.com'

const getYoutubeThumbnailContent = (id: string): ImageWeb => {
  const youtubeVideoId = getYoutubeId(id) || id
  if (youtubeVideoId) {
    return {
      title: youtubeVideoId,
      url: `${YOUTUBE_THUMBNAIL_HOST}/vi/${youtubeVideoId}/maxresdefault.jpg`,
      fallbackUrl: FALLBACK_THUMBNAIL.url,
      priority: true,
    }
  }

  return FALLBACK_THUMBNAIL
}

const getThumbnailContent = (contentType: string, id: string) => {
  if (contentType === CONTENTFUL_TYPES.CONTENT_YOUTUBE_VIDEO) {
    return getYoutubeThumbnailContent(id)
  }

  return FALLBACK_THUMBNAIL
}

export const formattedVideo = (formattedVideoProps: FormattedVideoProps): VideoProps => {
  if (!formattedVideoProps.videoContent) {
    return null
  }
  const { sys, fields: videoEntry } = formattedVideoProps.videoContent
  const contentType = sys?.contentType?.sys.id

  if (contentType === CONTENTFUL_TYPES.CONTENT_DAM_VIDEO) {
    const damVideoEntry = videoEntry as ContentEntry<TypeContentDamVideoSkeleton>['fields']
    const damVideoRef = damVideoEntry?.videoReference?.[0] || {}

    return {
      id: `${sys?.id}-${formattedVideoProps.index}`,
      url: damVideoRef?.url,
      thumbnail: {
        title: damVideoRef.title,
        url: damVideoRef.thumbnail,
        fallbackUrl: FALLBACK_THUMBNAIL.url,
      },
      description: damVideoEntry?.description,
      isVideoCookieAccepted: true,
    }
  }
  // For Youtube and Vimeo the videoReference should be a string id but could possibly come as url
  const videoReference = (
    videoEntry as ContentEntry<
      TypeContentVimeoVideoSkeleton | TypeContentYoutubeVideoSkeleton
    >['fields']
  )?.videoReference
  const noInitialCookieUrl = getNoInitialCookieUrlByContentType(contentType, videoReference)
  return {
    id: `${sys?.id}-${formattedVideoProps.index}`,
    url: noInitialCookieUrl,
    thumbnail: getThumbnailContent(contentType, videoReference),
    description: (videoEntry as ContentEntry<TypeContentDamVideoSkeleton>['fields'])
      ?.description,
    isVideoCookieAccepted: formattedVideoProps.isTargetingCookieSet,
    cookieSettingsTile: formattedVideoProps.cookieSettingsTile,
  }
}
